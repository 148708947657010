.upload-form {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.upload-form::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.3);
}

.upload-form .form {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    position: absolute;
    bottom: 100px;
    right: 50%;
    transform: translateX(50%);
    width: calc(100vw - 40px);
    max-width: 400px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;

}
.form-circle{
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.8);
}
.progressbar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

}

input[type="file"] {
    position: absolute;
    top: 0px;
    height: 150px;
    width: 150px;
    opacity: 0;
    cursor: pointer;
}
#file-upload-button {
    opacity: 0;
    background-color: red;
}
.circle-content {
     position: relative;
     width: 100%;
     height: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.circle-icon {
    height: 40px;
    width: 40px;
    background-color: white;
    mask-image: url(cloud.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}
.done-icon {
    height: 40px;
    width: 40px;
    background-color: white;
    mask-image: url(done.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}
h2{
    color: white;
    font-size: 1rem;
    text-align: center;
    margin: 0;
    padding: 0;
}
.done{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    animation: done 1s ease-in-out infinite;
    background-size: cover;
    border-radius: 50%;
}
.info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.done::before{
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
   background-color: rgba(38, 129, 38, 0.7);
    border-radius: 50%;
}
.filename {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 80%;
}